module services {
    export module applicationcore {
        export class packageTypeService implements interfaces.applicationcore.IPackageTypeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getDropdownList(searchText: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "PackageType/GetDropdownList", {
                    searchText: searchText
                });
            }
        }
    }
    angular.module("app").service("packageTypeService", services.applicationcore.packageTypeService);
}